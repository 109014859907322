<template>
  <div id="app">
    <div style="display:flex">
      <el-aside width="200px" style="height:calc(100vh - 20px)">
        <el-menu
          :default-active="defaultActive"
          class="el-menu-vertical-demo"
          background-color="#303445"
          text-color="#fff"
          active-text-color="#409EFF"
          @open="handleOpen"
          @close="handleClose"
        >
          <router-link :to="{path:'/admin/home'}">
            <el-menu-item index="/admin/home">
              <template slot="title">
                <i class="el-icon-s-cooperation" />
                <span slot="title">招聘管理</span>
              </template>
            </el-menu-item>
          </router-link>

          <router-link :to="{path:'/admin/about'}">
            <el-menu-item index="/admin/about">
              <template slot="title">
                <i class="el-icon-s-open" />
                <span slot="title">官网管理</span>
              </template>
            </el-menu-item>
          </router-link>

          <router-link :to="{path:'/admin/contact'}">
            <el-menu-item index="/admin/contact">
              <template slot="title">
                <i class="el-icon-phone" />
                <span slot="title">用户留言</span>
              </template>
            </el-menu-item>
          </router-link>

          <!-- <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-user-solid" />
              <span slot="title">权限管理</span>
            </template>
            <router-link :to="{path:'/admin/userMange'}"><el-menu-item index="1-1">用户列表</el-menu-item></router-link>
            <router-link :to="{path:'/admin/Userlist'}"><el-menu-item index="1-2">菜单列表</el-menu-item></router-link>
            <router-link :to="{path:'/admin/Rolelist'}"><el-menu-item index="1-3">角色列表</el-menu-item></router-link>
          </el-submenu> -->

          <router-link :to="{path:'/admin/datamange'}">
            <el-menu-item index="/admin/datamange">
              <template slot="title">
                <i class="el-icon-s-grid" />
                <span slot="title">数据管理</span>
              </template>
            </el-menu-item>
          </router-link>

          <router-link :to="{path:'/admin/Friendchain'}">
            <el-menu-item index="/admin/Friendchain">
              <template slot="title">
                <i class="el-icon-s-promotion" />
                <span slot="title">链接管理</span>
              </template>
            </el-menu-item>
          </router-link>

        </el-menu>
      </el-aside>
      <div style="flex:1;background-color: #F5F7FA;">
        <el-card class="box-card" style="width:calc(100vw - 235px);margin-left:10px">
          <div class="right-menu">
            <div class="demo-type">
              <div style="margin-top: -8px;margin-right: 6px;">
                <el-avatar
                  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                />
              </div>
              <div class="name">{{ userInfo ? userInfo.username : '' }}</div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="shouye">首页</el-dropdown-item>
                  <router-link to="/login"><el-dropdown-item command="logout" @click.native="logout">退出登录</el-dropdown-item></router-link>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-card>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-right:10px">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/home'}">招聘管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/about'}">官网管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/contact'}">联系我们</el-breadcrumb-item>
        </el-breadcrumb>-->
        <div class="mainfile">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultActive: ''
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created() {
    this.defaultActive = this.$route.path
  },
  methods: {
    logout() {
      console.log('nsd')
      localStorage.removeItem('token')
    }
  }
  // handleCommand(command) {
  //   this.$message('click on item ' + command)
  //   this.logout()
  // }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* text-align: center; */
  color: #2c3e50;
  height: calc(100vh - 20px);
  overflow: hidden;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #303445;
  color: #333;
  text-align: center;
  line-height: 200px;
}
.mainfile {
  width:calc(100vw - 235px);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  height: calc(100vh - 80px);
  margin: 10px;
  background-color: white;
  overflow-y: auto;
}
.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
}
.el-breadcrumb {
  margin-left: 21px;
  margin-top: 10px;
}
.right-menu {
  font-size: 15px;
  float: right;
  line-height: 15px;
}
.demo-type {
  display: flex;
}
.el-avatar {
  margin-top: -8px;
}
</style>
